<template>
  <div>
      <div v-if="getLoaded()" class="duck-form form-width-s3 form-center">
        <form @submit.prevent="saveForm">
          <fieldset class="form-block">
            <div class="form-container">
              <SmallTitle title="ข้อมูลโครงการ" />
              <div class="data-2col">
                <div class="row">
                  <div class="title">รหัสโครงการ</div>
                  <div class="detail">
                    {{ project.code }} - <router-link :to="'/project/edit/'+project.PID">edit</router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="title">ชื่อโครงการ</div>
                  <div class="detail">{{ project.name }}</div>
                </div>
                <div class="row">
                  <div class="title">ประเภทงาน</div>
                  <div class="detail">{{ project.cat | projectCatIdToName }}</div>
                </div>
              </div>
              
              <SmallTitle title="ข้อมูลสมาชิก" />
              <div class="data-2col">
                <div class="row">
                  <div class="title">ชื่อ-นามสกุล</div>
                  <div class="detail">
                    {{ member.first_name }} {{ member.last_name }} - <router-link :to="'/member/edit/'+member.MID">edit</router-link>
                  </div>
                </div>
                <div v-if="member.business_type==9" class="row">
                  <div class="title">ชื่อบริษัท</div>
                  <div class="detail">{{ member.company_name }}</div>
                </div>
                <div class="row">
                  <div class="title">โทรศัพท์</div>
                  <div class="detail"><a :href="'tel:'+member.phone">{{ member.phone }}</a></div>
                </div>
                <div class="row">
                  <div class="title">อีเมล์</div>
                  <div class="detail"><a :href="'mailto:'+member.email">{{ member.email }}</a></div>
                </div>
                <div class="row">
                  <div class="title">ที่อยู่</div>
                  <div class="detail">
                    {{ member.address }}
                    {{ member.subdistrict }}
                    {{ member.district }}
                    {{ member.province }}
                    {{ member.zipcode }}
                  </div>
                </div>
              </div>

              <SmallTitle title="ราคาที่เสนอ (ไม่รวม Vat)" />
              <div class="input">
                <input type="text" v-model="member.budget" :class="errorClass" required>
              </div>
            </div>
          </fieldset>
          <!-- end: General Informatin -->

          <fieldset class="form-block">
            <div class="h-title">
              <h3>เอกสารประกอบ</h3>
            </div>
            <div class="form-container">
              <SmallTitle title="ใบเสนอราคา" />
              <MultipleFileUploader type='auction_proposal' :parentId="projectId" :relateId="memberId" section="project" />

              <SmallTitle title="แผนการดำเนินการ" />
              <MultipleFileUploader type='auction_plan' :parentId="projectId" :relateId="memberId" section="project" />

              <FileUploaderRemark />
            </div>
          </fieldset>

          <!-- <FileUploader section="project" :dateRefer="project.date_create" :parentId="project.PID" :relateId="member.MID" /> -->

          <div class="button-block">
            <!-- <button type="button" @click='testClick'>Test</button><br> -->
            <button type="submit" class="btn btn-update">
              <i class="fa fa-save fa-lg"></i> เสนอราคา
            </button>
          </div>
        </form>

      </div>
  </div>
</template>

<script>
// // @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js'
// import {floatComma} from '@/utils/number/floatComma.js';

import SmallTitle           from '@/components/common/SmallTitle.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';
import FileUploaderRemark   from '@/components/file/FileUploaderRemark.vue';

export default {
  // name: 'User Edit',
  data() {
    return {
      errorClass: '',
      budget: 0,
      detail: '',
      member: {},
      memberId: 0,
      projectId: 0,
      project: {}
    }
  },
  components: {
    SmallTitle,
    MultipleFileUploader,
    FileUploaderRemark
  },
  created() {
    this.$store.commit('setPageTitle', 'Auction');
    this.$store.commit('setLoaderShow', true);
    
    this.projectId = this.$route.params.id;
    this.memberId  = this.$route.params.memberId;

    // get project data
    apiRequest
      .get('/project/'+this.projectId)
      .then( (res) => {
        this.project = res.data.result;
        apiRequest
          .get( '/auction/' + this.projectId+'/'+this.memberId)
          .then( (res) => {
            this.member = res.data.result;
            console.log('Raw budget : '+this.member.budget);
          });
          this.$store.commit('setLoaderShow', false);
      });
  },
  // watch:{
  //   member: {
  //     handler: function(newValue) {
  //       this.member.budget = floatComma(newValue.budget);
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    saveForm(e) {
      if(this.member.budget<=0){
        this.errorClass = 'error';
        return '';
      }

      this.loaderShow = true;
      this.member.project_id = this.projectId;
      apiRequest
        .post('/auction/save', this.member)
        .then(() => {
          this.$router.push('/project/view/' + this.projectId);
        });

      e.preventDefault();
    }
  }
}
</script>